// built with insights from https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
import React, { useEffect, useState } from "react";
import {
  eraseCookie,
  getLocalStorage,
  setLocalStorage,
} from "../components/utils";
import { onAuthStateChanged, Auth } from "firebase/auth";
import { auth } from "../components/firebase/firebase";

interface IUser {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  isAnonymous: boolean;
  metadata: any;
  photoURL: string | null;
  userID: string;
}

export interface AppContextType {
  user: IUser | null;
  setUser: any;
  logout: any;
  cookie_name: string;
  darkMode: string;
  setDarkMode: any;
}

const cookie_name = "ravenml_app_cookie_";

export const appContext = React.createContext<AppContextType>(
  {} as AppContextType
);
const Provider = ({ children }: any) => {
  const [user, setUser] = useState<null | any>(null);

  const [darkMode, setDarkMode] = useState(
    getLocalStorage("darkmode", false) === null
      ? "light"
      : getLocalStorage("darkmode", false) !== ("dark" || "light")
      ? getLocalStorage("darkmode", false)
      : "dark"
  );

  const logout = () => {
    setUser(null);
    eraseCookie(cookie_name);
    auth.signOut();
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // setUser(user);
      if (user) {
        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          photoURL,
          uid,
        } = user;
        const metadata = user;
        const currentUser: IUser = {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          photoURL,
          userID: uid,
        };

        setUser(currentUser);
      }
      // console.log("autho state changed");
    });
  }, []);

  const updateDarkMode = (darkMode: string) => {
    setDarkMode(darkMode);
    setLocalStorage("darkmode", darkMode, false);
  };

  return (
    <appContext.Provider
      value={{
        user,
        setUser,
        logout,
        cookie_name,
        darkMode,
        setDarkMode: updateDarkMode,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default ({ element }: any) => <Provider>{element}</Provider>;
